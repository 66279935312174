export const multimedi = (
  <a
    href="https://www.multimedi.be/en"
    className="underline underline-offset-2"
  >
    Multimedi
  </a>
);

export const CVLINKS = (
  <div className="inline">
    <a
      target="_blank"
      href="2024_12_CV_LEBRUN_Lucas_NL.pdf"
      className="underline underline-offset-4 text-base"
    >
      NL
    </a>{" "}
    |{" "}
    <a
      target="_blank"
      href="2024_12_CV_LEBRUN_Lucas_EN.pdf"
      className="underline underline-offset-4 text-base"
    >
      EN
    </a>{" "}
    |{" "}
    <a
      target="_blank"
      href="2024_12_CV_LEBRUN_Lucas_FR.pdf"
      className="underline underline-offset-4 text-base"
    >
      FR
    </a>
  </div>
);

export const background = {
  EN: (
    <div>
      <p>
        Hi, I'm Lucas Lebrun, Fullstack Java developer, originally from France,
        now living in Leuven, Belgium.
      </p>
      <p>
        After a rich working experience as developer / teacher / technician /
        artist for almost 5 years in the cultural sector, I’ve decided to take
        the leap to specialize completely into development and discover other
        sectors. In 2024 I’ve enrolled in an intensive 8 months full-time Java
        program with {multimedi}.
      </p>
      <p>My passions are problem-solving, new technologies, and design.</p>
      <p>
        With both a Master of Political Science (SciencesPo Paris) and of
        Programming applied to Art (Erasmushogeschool Brussel), I can admit that
        I'm addicted to learning.
      </p>
      <h3 className="text-xl mb-8">Resume: {CVLINKS} </h3>
    </div>
  ),
  FR: (
    <div>
      <p>
        Bonjour, je suis Lucas Lebrun, développeur Fullstack Java, originaire de
        France et actuellement installé à Louvain, Belgique.
      </p>
      <p>
        Après une riche expérience professionnelle de près de 5 ans dans le
        secteur culturel en tant que développeur, enseignant, technicien et
        artiste, j'ai décidé de me spécialiser complètement dans le
        développement et de découvrir d'autres secteurs. En 2024, j'ai suivi un
        programme intensif de 8 mois à plein temps en Java avec {multimedi}.
      </p>
      <p>
        Mes passions incluent la résolution de problèmes, les nouvelles
        technologies et le design.{" "}
      </p>
      <p>
        Diplômé d'un Master en Sciences Politiques (SciencesPo Paris) et en
        Programmation appliquée à l'Art (Erasmushogeschool Brussel), je peux
        dire que je suis accro à l'apprentissage.
      </p>
      <h3 className="text-xl mb-8">Curriculum vitæ : {CVLINKS}</h3>
    </div>
  ),
  NL: (
    <div>
      <p>
        Hallo, ik ben Lucas Lebrun, Fullstack Java developer, oorspronkelijk uit
        Frankrijk en nu woonachtig in Leuven, België.
      </p>
      <p>
        Na een rijke werkervaring van bijna 5 jaar als developer / docent /
        technicus / kunstenaar in de culturele sector, heb ik besloten om de
        sprong te wagen naar een specialisatie in ontwikkeling en om andere
        sectoren te ontdekken. In 2024 heb ik me ingeschreven voor een intensief
        8 maanden durend fulltime Java programma bij {multimedi}.
      </p>
      <p>
        Mijn passies zijn probleemoplossen, nieuwe technologieën en design.{" "}
      </p>
      <p>
        Met zowel een Master in Politieke Wetenschappen (SciencesPo Paris) als
        in Programmering toegepast op Kunst (Erasmushogeschool Brussel), kan ik
        toegeven dat ik verslaafd ben aan leren.
      </p>
      <h3 className="text-xl mb-8">Curriculum vitæ: {CVLINKS}</h3>
    </div>
  ),
};
