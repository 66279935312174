import React, { useEffect } from "react";
import { background, CVLINKS } from "../content/about-background";
import { SKILLS } from "../content/skills";
import { projects } from "../content/projects";
import { main } from "../content/main-intro";
import AOS from "aos";

export default function Main({ language }) {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div id="main" className="flex flex-col gap-8 text-xl p-2 sm:p-6 ">
      <div className=" flex flex-col gap-2">
        <h1 className="sm:text-6xl text-5xl">Lucas Lebrun</h1>
        <h2 className="text-3xl">Full Stack Developer</h2>
        <h2 className="text-3xl">Java / Angular</h2>
      </div>
      <div className="w-full text-lg boder flex justify-end ">
        <div className="min-w-[50%]">
          {language === "EN" && (
            <div data-aos="fade-in" data-aos-duration="1000">
              {main["EN"]}
            </div>
          )}
          {language === "FR" && (
            <div data-aos="fade-in" data-aos-duration="1000">
              {main["FR"]}
            </div>
          )}
          {language === "NL" && (
            <div data-aos="fade-in" data-aos-duration="1000">
              {main["NL"]}
            </div>
          )}
        </div>
      </div>
      <div className="w-full text-lg boder flex justify-center ">
        <div className="min-w-[50%]">
          <div className="max-w-4xl flex flex-col gap-3">
            <h2 className="text-5xl text-center mb-4">Skills</h2>
            <div className="flex gap-y-4 text-2xl justify-between flex-wrap w-full  ">
              {SKILLS.map((category) => (
                <div key={category.category} className="w-1/2 md:w-1/4 pr-2 ">
                  <h3 className="mb-2 underline underline-offset-4">
                    {category.category}
                  </h3>
                  <ul>
                    {category.skills.map((skill) => (
                      <li key={skill}>{skill}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full text-lg  flex justify-center ">
        <div className="max-w-6xl w-full flex flex-col gap-3 mt-20">
          <h2 className="text-5xl ">Featured Projects </h2>
          <span className="text-3xl  text-red-500">
            (Since I am not actively looking for new opportunities, some
            projects are currently in idle mode.)
          </span>

          {projects.map((project, index) => (
            <div
              key={index}
              className={`mb-8 flex flex-col font-light h-[30rem] justify-center relative   `}
            >
              <div
                className="hidden sm:block absolute w-[100%] h-[90%] opacity-50 bg-contain bg-no-repeat bg-center right-1/2 top-1/2 transform translate-x-1/2 -translate-y-1/2"
                style={{
                  backgroundImage: `url(${
                    process.env.PUBLIC_URL + project.img
                  })`,
                }}
              ></div>
              <div
                className=" sm:hidden block absolute w-[100%] h-[90%] opacity-50 bg-contain bg-no-repeat bg-center right-1/2 top-1/2 transform translate-x-1/2 -translate-y-1/2"
                style={{
                  backgroundImage: `url(${
                    process.env.PUBLIC_URL + project.imgPhone
                  })`,
                }}
              ></div>
              <div className="flex flex-col gap-0.5">
                <div
                  className={` -mb-[5px] text-4xl sm:text-6xl lg:text-7xl font-normal w-fit`}
                >
                  <div className="title relative background text-right  ">
                    {project.title}
                    <a
                      target="_blank"
                      href={project.projectLink}
                      className={`absolute w-full h-full top-0 left-0  block`}
                    ></a>
                  </div>
                </div>
                <p className="text-lg background max-w-[80%] sm:max-w-full w-fit text-left">
                  {project.description}
                </p>
                {project.disabled == true && (
                  <p className="text-lg text-red-500  font-bold background max-w-[80%] sm:max-w-full w-fit text-left">
                    TEMPORARILY DISABLED
                  </p>
                )}
                {project.idlemode == true && (
                  <p className="text-lg text-red-500  font-bold background max-w-[80%] sm:max-w-full w-fit text-left">
                    (Idle mode: 1min startup time !)
                  </p>
                )}
                <div className="flex justify-start gap-[1px] flex-wrap">
                  {project.badges.map((badge, indexBadge) => (
                    <span
                      key={indexBadge}
                      className={`border px-[4px] rounded background badge"
                                  `}
                    >
                      {badge}
                    </span>
                  ))}
                </div>
                {project.projectLink && (
                  <a
                    href={project.projectLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" background underline-offset-2 text-lg flex gap-1 justify-end items-center h-min w-fit "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12.068.016l-3.717 3.698 5.263 5.286h-13.614v6h13.614l-5.295 5.317 3.718 3.699 11.963-12.016z" />
                    </svg>
                    See in action<br></br>
                  </a>
                )}

                {project.sourceCodeLink && (
                  <a
                    href={project.sourceCodeLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" background underline-offset-2 flex gap-1 justify-end items-center w-fit"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12.068.016l-3.717 3.698 5.263 5.286h-13.614v6h13.614l-5.295 5.317 3.718 3.699 11.963-12.016z" />
                    </svg>
                    Source code
                  </a>
                )}
                {project.backendRepo && (
                  <a
                    href={project.backendRepo}
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" background underline-offset-2 flex gap-1 justify-end items-center w-fit"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12.068.016l-3.717 3.698 5.263 5.286h-13.614v6h13.614l-5.295 5.317 3.718 3.699 11.963-12.016z" />
                    </svg>
                    Backend Repo
                  </a>
                )}
                {project.frontendRepo && (
                  <a
                    href={project.frontendRepo}
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" background underline-offset-2 flex gap-1 justify-end items-center w-fit"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12.068.016l-3.717 3.698 5.263 5.286h-13.614v6h13.614l-5.295 5.317 3.718 3.699 11.963-12.016z" />
                    </svg>
                    Frontend Repo
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
